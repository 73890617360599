export default {
	"assert": true,
	"node:assert": [">= 14.18 && < 15", ">= 16"],
	"assert/strict": ">= 15",
	"node:assert/strict": ">= 16",
	"async_hooks": ">= 8",
	"node:async_hooks": [">= 14.18 && < 15", ">= 16"],
	"buffer_ieee754": ">= 0.5 && < 0.9.7",
	"buffer": true,
	"node:buffer": [">= 14.18 && < 15", ">= 16"],
	"child_process": true,
	"node:child_process": [">= 14.18 && < 15", ">= 16"],
	"cluster": ">= 0.5",
	"node:cluster": [">= 14.18 && < 15", ">= 16"],
	"console": true,
	"node:console": [">= 14.18 && < 15", ">= 16"],
	"constants": true,
	"node:constants": [">= 14.18 && < 15", ">= 16"],
	"crypto": true,
	"node:crypto": [">= 14.18 && < 15", ">= 16"],
	"_debug_agent": ">= 1 && < 8",
	"_debugger": "< 8",
	"dgram": true,
	"node:dgram": [">= 14.18 && < 15", ">= 16"],
	"diagnostics_channel": [">= 14.17 && < 15", ">= 15.1"],
	"node:diagnostics_channel": [">= 14.18 && < 15", ">= 16"],
	"dns": true,
	"node:dns": [">= 14.18 && < 15", ">= 16"],
	"dns/promises": ">= 15",
	"node:dns/promises": ">= 16",
	"domain": ">= 0.7.12",
	"node:domain": [">= 14.18 && < 15", ">= 16"],
	"events": true,
	"node:events": [">= 14.18 && < 15", ">= 16"],
	"freelist": "< 6",
	"fs": true,
	"node:fs": [">= 14.18 && < 15", ">= 16"],
	"fs/promises": [">= 10 && < 10.1", ">= 14"],
	"node:fs/promises": [">= 14.18 && < 15", ">= 16"],
	"_http_agent": ">= 0.11.1",
	"node:_http_agent": [">= 14.18 && < 15", ">= 16"],
	"_http_client": ">= 0.11.1",
	"node:_http_client": [">= 14.18 && < 15", ">= 16"],
	"_http_common": ">= 0.11.1",
	"node:_http_common": [">= 14.18 && < 15", ">= 16"],
	"_http_incoming": ">= 0.11.1",
	"node:_http_incoming": [">= 14.18 && < 15", ">= 16"],
	"_http_outgoing": ">= 0.11.1",
	"node:_http_outgoing": [">= 14.18 && < 15", ">= 16"],
	"_http_server": ">= 0.11.1",
	"node:_http_server": [">= 14.18 && < 15", ">= 16"],
	"http": true,
	"node:http": [">= 14.18 && < 15", ">= 16"],
	"http2": ">= 8.8",
	"node:http2": [">= 14.18 && < 15", ">= 16"],
	"https": true,
	"node:https": [">= 14.18 && < 15", ">= 16"],
	"inspector": ">= 8",
	"node:inspector": [">= 14.18 && < 15", ">= 16"],
	"inspector/promises": [">= 19"],
	"node:inspector/promises": [">= 19"],
	"_linklist": "< 8",
	"module": true,
	"node:module": [">= 14.18 && < 15", ">= 16"],
	"net": true,
	"node:net": [">= 14.18 && < 15", ">= 16"],
	"node-inspect/lib/_inspect": ">= 7.6 && < 12",
	"node-inspect/lib/internal/inspect_client": ">= 7.6 && < 12",
	"node-inspect/lib/internal/inspect_repl": ">= 7.6 && < 12",
	"os": true,
	"node:os": [">= 14.18 && < 15", ">= 16"],
	"path": true,
	"node:path": [">= 14.18 && < 15", ">= 16"],
	"path/posix": ">= 15.3",
	"node:path/posix": ">= 16",
	"path/win32": ">= 15.3",
	"node:path/win32": ">= 16",
	"perf_hooks": ">= 8.5",
	"node:perf_hooks": [">= 14.18 && < 15", ">= 16"],
	"process": ">= 1",
	"node:process": [">= 14.18 && < 15", ">= 16"],
	"punycode": ">= 0.5",
	"node:punycode": [">= 14.18 && < 15", ">= 16"],
	"querystring": true,
	"node:querystring": [">= 14.18 && < 15", ">= 16"],
	"readline": true,
	"node:readline": [">= 14.18 && < 15", ">= 16"],
	"readline/promises": ">= 17",
	"node:readline/promises": ">= 17",
	"repl": true,
	"node:repl": [">= 14.18 && < 15", ">= 16"],
	"node:sea": [">= 20.12 && < 21", ">= 21.7"],
	"smalloc": ">= 0.11.5 && < 3",
	"_stream_duplex": ">= 0.9.4",
	"node:_stream_duplex": [">= 14.18 && < 15", ">= 16"],
	"_stream_transform": ">= 0.9.4",
	"node:_stream_transform": [">= 14.18 && < 15", ">= 16"],
	"_stream_wrap": ">= 1.4.1",
	"node:_stream_wrap": [">= 14.18 && < 15", ">= 16"],
	"_stream_passthrough": ">= 0.9.4",
	"node:_stream_passthrough": [">= 14.18 && < 15", ">= 16"],
	"_stream_readable": ">= 0.9.4",
	"node:_stream_readable": [">= 14.18 && < 15", ">= 16"],
	"_stream_writable": ">= 0.9.4",
	"node:_stream_writable": [">= 14.18 && < 15", ">= 16"],
	"stream": true,
	"node:stream": [">= 14.18 && < 15", ">= 16"],
	"stream/consumers": ">= 16.7",
	"node:stream/consumers": ">= 16.7",
	"stream/promises": ">= 15",
	"node:stream/promises": ">= 16",
	"stream/web": ">= 16.5",
	"node:stream/web": ">= 16.5",
	"string_decoder": true,
	"node:string_decoder": [">= 14.18 && < 15", ">= 16"],
	"sys": [">= 0.4 && < 0.7", ">= 0.8"],
	"node:sys": [">= 14.18 && < 15", ">= 16"],
	"test/reporters": ">= 19.9 && < 20.2",
	"node:test/reporters": [">= 18.17 && < 19", ">= 19.9", ">= 20"],
	"test/mock_loader": ">= 22.3 && < 22.7",
	"node:test/mock_loader": ">= 22.3 && < 22.7",
	"node:test": [">= 16.17 && < 17", ">= 18"],
	"timers": true,
	"node:timers": [">= 14.18 && < 15", ">= 16"],
	"timers/promises": ">= 15",
	"node:timers/promises": ">= 16",
	"_tls_common": ">= 0.11.13",
	"node:_tls_common": [">= 14.18 && < 15", ">= 16"],
	"_tls_legacy": ">= 0.11.3 && < 10",
	"_tls_wrap": ">= 0.11.3",
	"node:_tls_wrap": [">= 14.18 && < 15", ">= 16"],
	"tls": true,
	"node:tls": [">= 14.18 && < 15", ">= 16"],
	"trace_events": ">= 10",
	"node:trace_events": [">= 14.18 && < 15", ">= 16"],
	"tty": true,
	"node:tty": [">= 14.18 && < 15", ">= 16"],
	"url": true,
	"node:url": [">= 14.18 && < 15", ">= 16"],
	"util": true,
	"node:util": [">= 14.18 && < 15", ">= 16"],
	"util/types": ">= 15.3",
	"node:util/types": ">= 16",
	"v8/tools/arguments": ">= 10 && < 12",
	"v8/tools/codemap": [">= 4.4 && < 5", ">= 5.2 && < 12"],
	"v8/tools/consarray": [">= 4.4 && < 5", ">= 5.2 && < 12"],
	"v8/tools/csvparser": [">= 4.4 && < 5", ">= 5.2 && < 12"],
	"v8/tools/logreader": [">= 4.4 && < 5", ">= 5.2 && < 12"],
	"v8/tools/profile_view": [">= 4.4 && < 5", ">= 5.2 && < 12"],
	"v8/tools/splaytree": [">= 4.4 && < 5", ">= 5.2 && < 12"],
	"v8": ">= 1",
	"node:v8": [">= 14.18 && < 15", ">= 16"],
	"vm": true,
	"node:vm": [">= 14.18 && < 15", ">= 16"],
	"wasi": [">= 13.4 && < 13.5", ">= 18.17 && < 19", ">= 20"],
	"node:wasi": [">= 18.17 && < 19", ">= 20"],
	"worker_threads": ">= 11.7",
	"node:worker_threads": [">= 14.18 && < 15", ">= 16"],
	"zlib": ">= 0.5",
	"node:zlib": [">= 14.18 && < 15", ">= 16"]
}
;